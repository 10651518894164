<template>
  <a href="" title="imprimir" @click.prevent="generateReport">
    <div v-if="!this.enProceso">
      <i class="material-icons opacity-10">print</i>
    </div>

    <div v-else>
      <b-spinner small label=""></b-spinner>
    </div>
    <vue-html2pdf :show-layout="false" :html-to-pdf-options="htmlToPdfOptions" :float-layout="true" :enable-download="false" @hasDownloaded="hasDownloaded($event)" :preview-modal="true" filename="barcode-preview" :paginate-elements-by-height="900" :pdf-quality="2" :manual-pagination="false" ref="html2Pdf">
      <section slot="pdf-content">

        <div class="mt-2">
          <b-row>
            <b-col>
              <!-- <barcode :value="code" height=40 width=2 fontSize=14 textMargin=1 :text="category.nombre.toUpperCase().substring(0,3) + '-' + code" style="margin-left: 5px"> -->
                <barcode :value="code" height=40 width=2 fontSize=10 textMargin=1 :text="category.nombre.toUpperCase().substring(0,3) + '-' + code" style="margin-left: 5px">
                ERROR.
              </barcode>
            </b-col>
          </b-row>
          <!-- <b-row>
            <p class="subtitle"><b>{{code}}</b></p>
          </b-row> -->
        </div>
        <!-- <div class="html2pdf__page-break" />
        <div class="mt-4" style="margin-left: 40px">
          <b-row>
            <b-col>
              <barcode :value="code" height=25 width=1 fontSize=12>
                Show this if the rendering fails.
              </barcode>
            </b-col>
          </b-row>
        </div> -->

      </section>
    </vue-html2pdf>

  </a>

</template>

<script>
export default {
  props: ["code","category"],

  data() {
    return {
      enProceso: false,
      descargado: false,
      htmlToPdfOptions: {
        html2canvas: {
          scale: 10,
        },
        jsPDF: {
          unit: "cm",
          format: [2.5, 5],
          orientation: "l",
        },
      },
    };
  },

  methods: {
    generateReport() {
      this.enProceso = true;
      this.$refs.html2Pdf.generatePdf();
    },

    hasDownloaded($event) {
      this.enProceso = false;
      this.close();
    },

    close() {
      // destroy the vue listeners, etc
      this.$destroy();
    },
  },

  mounted(){

  }
};
</script>

<style lang="scss">
.subtitle {
  color: black !important;
  font-family: "Calibri", sans-serif;
  font-size: 30px;
  margin-top: -9px;
  margin-left: 105px
}
</style>
